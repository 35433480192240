<!--
Content
-->
<template>
  <div class="container">
    <Html :data="this.data" />
  </div>
</template>

<script>

import Html from "@/components/partials/Html";

export default {
  name: "module0",
  components: {Html},
  props: {
    data: {data: [String], required: true}
  },
  data() {
    return {
      isVisible: false,
    }
  }
}
</script>

<style scoped>

</style>